<template>
	<div>
	<LoadingScreen v-if="loading_screen" />
	<contentHeader title="Data Percetakan" url="/data-percetakan" subTitle="Tambah Data Percetakan" />
	<div class="content-header">
		<div class="container-fluid">
			<div class="row justify-content-center">
				<div class="col">
					<div class="card">
						<div class="card-header row d-flex align-items-center">
	            			<h3 class="card-title col-6">Tambah Data Percetakan</h3>
	            		</div>
	            		<div class="card-body row">
	            			<div class="col-lg-2"></div>
	            			<div class="col-lg-8">
	            				<form @submit.prevent="updateDataPercetakan">
		            				<div class="px-lg-2 ">
		            					<div class="form-group">
					                		<label for="kode">Kode Percetakan </label>
					                		<input type="text" class="form-control" id="kode" name="kode" required="" autocomplete="off" disabled="" v-model="percetakan.kode">
					                	</div>
					                	<div class="form-group">
					                		<label for="nama">Nama </label>
					                		<input type="text" class="form-control" id="nama" name="nama" autocomplete="off" v-model="percetakan.nama">
					                	</div>
					                	<div class="form-group">
					                		<label for="fungsi">Fungsi </label>
					                		<textarea class="form-control" v-model="percetakan.fungsi"></textarea>
					                	</div>
					                	<div class="form-group">
					                		<label for="golongan">Golongan </label>
					                		<select class="custom-select form-control" v-model="percetakan.golongan" required="">
					                			<option selected="" hidden=""></option>
					                			<option value="bahan jadi">Bahan Jadi</option>
					                			<option value="bahan baku">Bahan Baku</option>
					                		</select>
					                	</div>
					                	<div class="form-group">
					                		<label for="nama">Satuan </label>
					                		<select class="custom-select form-control" v-model="percetakan.satuan" required="">
					                			<option selected="" hidden=""></option>
					                			<option value="pcs">Pcs</option>
					                			<option value="tube">Tube</option>
					                			<option value="ml">Ml</option>
					                			<option value="fls">Fls</option>
					                			<option value="cream">Cream</option>
					                			<option value="vial">Vial</option>
					                			<option value="bungkus">Bungkus</option>
					                			<option value="botol">Botol</option>
					                			<option value="tablet">Tablet</option>
					                			<option value="kaplet">Kaplet</option>
					                			<option value="gram">Gram</option>
					                			<option value="pot">Pot</option>
					                			<option value="strip">Strip</option>
					                			<option value="box">Box</option>
					                			<option value="ampl">Ampl</option>
					                			<option value="cc">CC</option>
					                			<option value="kotak">Kotak</option>
					                			<option value="lembar">Lembar</option>
					                			<option value="kapsul">Kapsul</option>
					                			<option value="kaleng">Kaleng</option>
					                		</select>
					                	</div>
					                	<div class="form-group">
						                    <label>Harga <span class="text-danger">*</span></label>
						                    <div class="row">
						                    	<div class="col">
						                    		<span>Harga Beli</span>
						                    		<div class="input-group mb-3">
									                  <div class="input-group-prepend">
									                    <div type="button" class="btn btn-secondary">Rp.</div>
									                  </div>
									                  <!-- /btn-group -->
						                    		  <input class="form-control" type="number" required="" name="harga_beli" v-model="percetakan.harga_beli">
									                </div>
						                    	</div>
						                    	<div class="col">
						                    		<span>Harga Jual</span>
						                    		<div class="input-group mb-3">
									                  <div class="input-group-prepend">
									                    <div type="button" class="btn btn-secondary">Rp.</div>
									                  </div>
									                  <!-- /btn-group -->
						                    		  <input class="form-control" type="number" required="" name="harga_jual" v-model="percetakan.harga_jual">
									                </div>
						                    	</div>
						                    </div>
						                </div>
					                	<div class="form-group">
					                		<div class="row">
					                			<div class="col">
					                				<label for="minimal_stok">Minimal Stok </label>
					                				<input type="number" class="form-control" id="minimal_stok" name="minimal_stok" autocomplete="off" required="" v-model="percetakan.minimal_stok">	
					                			</div>
					                			<div class="col">
					                				<label for="maksimal_stok">Maksimal Stok </label>
					                				<input type="number" class="form-control" id="maksimal_stok" required="" name="maksimal_stok" autocomplete="off" v-model="percetakan.maksimal_stok">
					                			</div>
					                		</div>
					                	</div>
					                	<div class="form-group">
					                		<label for="lokasi">Lokasi </label>
					                		<input type="text" class="form-control" id="lokasi" name="lokasi" required="" autocomplete="off" v-model="percetakan.lokasi">
					                	</div>
					                	<div class="form-group">
						                    <label>Fee </label>
						                    <div class="row">
						                    	<div class="col">
						                    		<span>Fee Dokter</span>
						                    		<div class="input-group">
									                  <div class="input-group-prepend">
									                    <div type="button" class="btn btn-secondary">Rp.</div>
									                  </div>
									                  <!-- /btn-group -->
						                    		  <input class="form-control" type="number" step="any" name="fee_dokter" v-model="percetakan.fee_dokter">
									                </div>
						                    	</div>
						                    	<div class="col">
						                    		<span>Fee Beautician</span>
						                    		<div class="input-group">
									                  <div class="input-group-prepend">
									                    <div type="button" class="btn btn-secondary">Rp.</div>
									                  </div>
									                  <!-- /btn-group -->
						                    		  <input class="form-control" type="number" step="any" name="fee_beautician" v-model="percetakan.fee_beautician">
									                </div>
						                    	</div>
						                    	<div class="col">
						                    		<span>Fee Sales</span>
						                    		<div class="input-group">
									                  <div class="input-group-prepend">
									                    <div type="button" class="btn btn-secondary">Rp.</div>
									                  </div>
									                  <!-- /btn-group -->
						                    		  <input class="form-control" type="number" step="any" name="fee_sales" v-model="percetakan.fee_sales">
									                </div>
						                    	</div>
						                    </div>
						                </div>
					                	<button type="submit" class="btn btn-primary d-flex align-items-center mt-4">
											<div>Submit</div>	
											<div class="spinner-border spinner-border-sm ml-2" v-if="loading" role="status">
											  <span class="sr-only">Loading...</span>
											</div>
										</button>
		            				</div>
		            			</form>
	            			</div>
	            			<div class="col-lg-2"></div>
	            		</div>
					</div>
				</div>
			</div>
		</div>
	</div>
		
	</div>
</template>

<script>
	import ContentHeader from '@/components/ContentHeader2'
	import LoadingScreen from '@/components/LoadingScreen'

	import { onMounted, ref } from 'vue'
	import axios from 'axios'
	import Swal from 'sweetalert2'
	import router from '@/router'
	import { useRoute } from 'vue-router'

	export default{
		components: {
			ContentHeader,
			LoadingScreen
		},
		setup() {
			const route = useRoute()
			const percetakan_id = route.params.id
			const loading = ref(false)
			const percetakan = ref([])
			const loading_screen = ref(true)

			const getPercetakan = async () => {
				let { data } = await axios.get(`api/produk/getWhereId/${percetakan_id}`)
				
				if (data == 'kosong') {
					loading_screen.value = false
					percetakan.value = []
				} else {
					loading_screen.value = false
					percetakan.value = data
				}
			}

			const updateDataPercetakan = () => {
				loading.value = true
				axios.put(`api/produk/update/${percetakan_id}`, percetakan.value)
				.then((response) => {
					Swal.fire({
					  title: 'Berhasil!',
					  text: 'Berhasil mengedit data',
					  icon: 'success',
					  showConfirmButton: false,
					  timer: 1500
					})	
					loading.value = false
		    		console.log(response.data)
		    		router.push('/data-percetakan')
		    	})
		    	.catch((error) => {
		    		Swal.fire({
					  title: 'Gagal!',
					  text: 'Gagal mengedit data',
					  icon: 'error',
					  confirmButtonText: 'Ok'
					})	
					loading.value = false
		    		console.log(error)
		    	})	
			}

			onMounted(() => {
				getPercetakan()
			})

			return {
				percetakan, loading, updateDataPercetakan, loading_screen
			}
		}
	}

	
</script>